<template>
  <div>
    <div
      style="
        padding-top: 14px;
        border-bottom: 1px solid #ccc;
        padding-bottom: 10px;
        margin-bottom: 10px;
      "
    >
      <el-breadcrumb>
        <el-breadcrumb-item>企业管理</el-breadcrumb-item>
        <el-breadcrumb-item>企业宣讲会</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div style="width: 1000px; margin: 0 auto; padding: 20px; border: 1px solid #ccc">
      <el-form ref="form" :model="EditItem" label-width="120px">
        <el-row>
          <el-col :span="24">
            <el-form-item label="宣讲会标题" required>
              <el-input
                v-model="EditItem.title_main"
                placeholder="请输入宣讲会标题"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row style="margin-top: 10px">
          <el-col :span="24">
            <el-form-item label="宣讲会副标题">
              <el-input
                v-model="EditItem.title_desc"
                placeholder="请输入宣讲会副标题"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row style="margin-top: 10px">
          <el-col :span="12">
            <el-form-item label="开始时间" required>
              <el-date-picker
                type="datetime"
                placeholder="选择日期"
                v-model="EditItem.stime"
                value-format="yyyy-MM-dd HH:mm:ss"
                style="width: 300px"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="报名截止时间" required>
              <el-date-picker
                type="datetime"
                placeholder="选择日期"
                v-model="EditItem.etime"
                value-format="yyyy-MM-dd HH:mm:ss"
                style="width: 300px"
              ></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="24">
            <el-form-item label="宣讲会地点" required>
              <el-input
                v-model="EditItem.xjh_address"
                placeholder="请输入宣讲会地点"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row style="margin-top: 10px">
          <el-col :span="24">
            <el-form-item label="宣讲会内容">
              <UEditor
                ref="ue"
                @ready="editorReady1"
                style="width: 100%; line-height: initial"
              ></UEditor>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row style="margin-top: 10px">
          <el-col :span="24">
            <el-form-item label="企业简介">
              <UEditor
                ref="ue"
                @ready="editorReady2"
                style="width: 100%; line-height: initial"
              ></UEditor>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row style="margin-top: 10px">
          <el-col :span="24">
            <el-form-item label="招聘岗位">
              <table
                class="dttable"
                v-for="(job, i) in EditItem.job_desc"
                :key="i"
                style="margin-bottom: 10px"
              >
                <tr>
                  <td class="tdheadersml" style="width: 15%">职位名称</td>
                  <td class="tdheadersml" style="width: 15%">职位类型</td>
                  <td class="tdheadersml" style="width: 15%">招聘人数</td>
                  <td class="tdheadersml" style="width: 15%">薪资待遇</td>
                  <td class="tdheadersml" style="width: 40%">工作城市</td>
                </tr>

                <tr>
                  <td>
                    <input class="tdinput" v-model="job.job_name" />
                  </td>
                  <td>
                    <input class="tdinput" v-model="job.job_type" />
                  </td>
                  <td>
                    <input class="tdinput" v-model="job.job_num" />
                  </td>
                  <td>
                    <select class="tdinput" v-model="job.salary" style="height: 28px">
                      <option>1000-2000</option>
                      <option>2000-3000</option>
                      <option>3000-4000</option>
                      <option>4000-5000</option>
                      <option>5000-6000</option>
                      <option>6000-7000</option>
                      <option>7000-8000</option>
                      <option>8000-9000</option>
                      <option>9000-10000</option>
                      <option>10000以上</option>
                    </select>
                  </td>
                  <td>
                    <el-cascader
                      ref="mycascader"
                      v-model="job.job_city"
                      :options="cityOptions"
                      size="small"
                      style="width: 90%"
                      clearable
                      @change="onChangeCity"
                      placeholder="请选择工作城市"
                    ></el-cascader>
                  </td>
                </tr>
                <tr>
                  <td class="tdheadersml" colspan="2">工作地点</td>
                  <td class="tdheadersml" colspan="2">职位要求</td>
                  <td class="tdheadersml">职位简介</td>
                </tr>
                <tr>
                  <td colspan="2">
                    <textarea class="tdinput" v-model="job.job_address"></textarea>
                  </td>
                  <td colspan="2">
                    <textarea class="tdinput" v-model="job.job_require"></textarea>
                  </td>
                  <td style="position: relative">
                    <textarea class="tdinput" v-model="job.job_desc"></textarea>
                    <div class="removebtn" @click="removeJob(i)">
                      <i class="el-icon-remove-outline"></i>
                    </div>
                  </td>
                </tr>
              </table>
              <div>
                <div style="text-align: center">
                  <el-button size="small" icon="el-icon-plus" @click="addJob"
                    >添加岗位</el-button
                  >
                </div>
              </div>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row style="margin-top: 10px">
          <el-col :span="24">
            <el-form-item label="附件（可上传企业易拉宝）" label-width="180px">
              <div
                class="fileitem"
                v-for="(file, i) in EditItem.files"
                :key="i"
                :style="{ backgroundImage: 'url(' + file.src + ')' }"
              >
                <div class="removeimg" @click="removeImage(i)">
                  <i class="el-icon-remove-outline"></i>
                </div>
              </div>
              <div class="fileitem">
                <el-upload
                  style="display: inline-block"
                  action
                  :multiple="true"
                  :http-request="uploadOss"
                  :on-success="handleFileUpload"
                  :show-file-list="false"
                  accept="image/png, image/jpeg"
                  name="image"
                >
                  <i class="el-icon-camera" style="font-size: 50px"></i>
                </el-upload>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <div style="max-width: 1000px; padding: 20px; text-align: right">
        <el-button
          icon="el-icon-check"
          style="margin-left: 10px"
          type="primary"
          @click="saveFrom()"
          :loading="loading"
          >保存</el-button
        >

        <el-button
          icon="el-icon-view"
          style="margin-left: 10px"
          type="primary"
          @click="viewForm()"
          >预览</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import UEditor from "../../notify/ueditor.vue";
import citys from "../../../utils/arae_value.js";
// import Detail from "./com/resumeDetail.vue";

export default {
  name: "companInfo",
  components: {
    UEditor,
  },
  data() {
    return {
      editor1: null,
      editor2: null,
      cityOptions: [],

      EditItem: {
        id: 0,
        html_content: "",
        enter_desc: "",
        job_desc: [],
        stime: "",
        etime: "",
        xjh_address: "",
        title_main: "",
        title_desc: "",
        enter_info: "",
        files: [],
      },
      loading: false,
    };
  },
  mounted() {
    citys.map((a) => {
      a.children &&
        a.children.map((b) => {
          delete b.children;
        });
    });
    // console.log(278, citys)
    this.cityOptions = citys;

    this.EditItem.id = parseInt(this.$Request("id"));
    if (this.EditItem.id > 0) {
      this.getData();
    } else {
      this.addJob();
    }

    setTimeout(() => {
      $(".el-dialog__body").height("80vh");
      $(".el-dialog").css("margin-top", "5vh");
    }, 300);
  },
  methods: {
    onChangeCity(a, b) {
      console.log(a, b);
    },
    getData() {
      this.$http.post("/api/zp_xjh_info", { id: this.EditItem.id }).then((res) => {
        if (res.data) {
          let EditItem = res.data;

          setTimeout(() => {
            if (EditItem.html_content) {
              this.editor1.setContent(EditItem.html_content);
            }
            if (EditItem.enter_desc) {
              this.editor2.setContent(EditItem.enter_desc);
            }
            if (EditItem.job_desc) {
              try {
                EditItem.job_desc = JSON.parse(EditItem.job_desc);
              } catch (error) {}
            }
            if (EditItem.files) {
              EditItem.files = JSON.parse(EditItem.files);
            }
            this.EditItem = EditItem;
          }, 1000);
        }
      });
    },
    editorReady1(editorInstance) {
      this.editor1 = editorInstance;
    },
    editorReady2(editorInstance) {
      this.editor2 = editorInstance;
    },
    addJob() {
      let ndata = {
        job_name: "",
        job_type: "",
        job_num: "",
        job_city: "",
        city_name: "",
        job_address: "",
        job_require: "",
        job_desc: "",
        salary: "",
      };
      this.EditItem.job_desc.push(ndata);
    },
    handleFileUpload(e) {
      console.log(e);
      this.EditItem.files.push(e);
    },
    removeJob(i) {
      this.EditItem.job_desc.splice(i, 1);
    },
    removeImage(i) {
      this.EditItem.files.splice(i, 1);
    },

    saveFrom() {
      this.EditItem.html_content = this.editor1.getContent();
      this.EditItem.enter_desc = this.editor2.getContent();
      let EditItem = { ...this.EditItem };
     
      //获取城市名称
      for (let job of EditItem.job_desc) {
        if (job.job_city && job.job_city.length > 0) {
          if (job.job_city.length == 1) {
            for (let p of this.cityOptions) {
              if (p.value == job.job_city[0]) {
                job.city_name = p.label;
                break;
              }
            }
          } else {
            for (let p of this.cityOptions) {
              if (p.value == job.job_city[0]) {
                for (let c of p.children) {
                  if (c.value == job.job_city[1]) {
                    job.city_name = c.label;
                    break;
                  }
                }
                break;
              }
            }
          }
        }
      }
      EditItem.files = JSON.stringify(EditItem.files);
      EditItem.job_desc = JSON.stringify(EditItem.job_desc);
      if (!EditItem.title_main) {
        this.$message.error("请输入标题");
        return;
      }
      if (!EditItem.stime) {
        this.$message.error("请输入开始时间");
        return;
      }
      if (!EditItem.etime) {
        this.$message.error("请输入截止时间");
        return;
      }
      if (!EditItem.xjh_address) {
        this.$message.error("请输入宣讲会地址");
        return;
      }
      this.loading = true;

      this.$http.post("/api/zp_xjh_save", EditItem).then((res) => {
        this.$message.success("保存成功！");
        this.loading = false;
        this.$router.back();
      });
    },
    viewForm() {
      if (this.EditItem.id) {
        window.open("/zhaopin/#/qyxjh/" + this.EditItem.id);
      } else {
        this.$message.error("请先保存宣讲会");
      }
    },
    getJoinList() {
      let data = {};
      data.xjh_id = this.EditItem.id;
      data.page = this.page.current_page;
      data.pagesize = this.page.per_page;
      data.enter_id = this.enter_id;
      this.$http.post("/api/zp_xjhjoin_list", data).then((res) => {
        if (res.data && res.data.data) {
          res.data.data.map((a) => {
            let obj = this.getParentId(citys, a.city);
            a.cityName = obj ? obj.label : "";
          });
          this.tableData = res.data.data;
        }
        this.page = res.data.page;
      });
    },
    getParentId(list, iid) {
      if (list && list.length > 0) {
        for (let o of list) {
          if (o.value == iid) return o;
          const o_ = this.getParentId(o.children, iid);
          if (o_) return o_;
        }
      }
    },

    canParseToJson(str, parseFlag) {
      try {
        if (
          typeof JSON.parse(str) === "object" &&
          (Object.prototype.toString.call(JSON.parse(str)) === "[object Object]" ||
            Object.prototype.toString.call(JSON.parse(str)) === "[object Array]")
        ) {
          return parseFlag === true ? JSON.parse(str) : true;
        }
      } catch (e) {}
      return false;
    },
    handlePageChange(page) {
      this.page.current_page = page;
      this.getJoinList();
    },
    handleSizeChange(val) {
      this.page.current_page = 1;
      this.page.per_page = val;
      this.getJoinList(1);
    },
    xiangqing(row) {
      this.$http.post("/api/zp_resume_info", { id: row.stu_id }).then((res) => {
        // this.$http.post("/api/zp_resume_info", { id: 5003 }).then(res => {

        if (!res.data.tel) {
          res.data.tel = "***********";
        } else {
          res.data.tel = "***********";
        }

        if (!res.data.wechat) {
          res.data.wechat = "***********";
        } else {
          res.data.wechat = "***********";
        }

        if (res.data.award_annex && this.canParseToJson(res.data.award_annex)) {
          res.data.award_annex = JSON.parse(res.data.award_annex);
        }
        if (res.data.job_array) {
          res.data.job_array.map((a) => {
            let obj = this.getParentId(citys, a.city);
            a.cityName = obj ? obj.label : "";
          });
        }
        if (res.data.practice && this.canParseToJson(res.data.practice)) {
          res.data.practice = JSON.parse(res.data.practice);
        }
        if (res.data.project && this.canParseToJson(res.data.project)) {
          res.data.project = JSON.parse(res.data.project);
          res.data.project.map((a) => {
            if (a.annex && this.canParseToJson(a.annex)) {
              a.annex = JSON.parse(a.annex);
            }
          });
        }
        this.formData = res.data;
        this.dialogForm = true;
      });
    },

    chakan(row) {
      if (!this.job_id) {
        this.$message({
          type: "error",
          message: "请先选择职位!",
        });
        return false;
      }
      this.$http
        .post("/api/zp_gwyq_edit", {
          job_id: this.job_id,
          resume_id: row.id,
        })
        .then((res) => {
          this.$message({
            type: "success",
            message: `职位邀请成功!`,
          });
          this.dialogForm = false;
          this.getList();
        });
    },
    closeDialog() {
      this.$$parent(this, "entID", 0);
    },
  },
};
</script>

<style scoped lang="less">
.tdinput {
  width: 100%;
  border: 1px solid #dcdfe6;
}
.removebtn {
  width: 20px;
  height: 20px;
  position: absolute;
  right: -20px;
  top: 30px;
  line-height: 0;
  color: orangered;
  cursor: pointer;
}
.fileitem {
  width: 150px;
  height: 150px;
  border: 1px dotted #ccc;
  display: inline-block;
  vertical-align: top;
  margin: 0 10px 10px 0;
  cursor: pointer;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 50px;
  color: #ccc;
  background-position: center;
  background-repeat: norepeat;
  background-size: cover;
}

.removeimg {
  width: 30px;
  height: 30px;
  position: absolute;
  right: -15px;
  top: -10px;
  line-height: 0;
  color: orangered;
  cursor: pointer;
}
.removeimg {
  font-size: 25px;
}
</style>
